<template>
  <div>
    <p class="mb-3">
      Which of the following is the correct expression for the solubility product of
      <chemical-latex content="Mg3(PO4)2" />
      ?
    </p>

    <v-form @submit.prevent="submitResponse">
      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
        <div v-for="option in options1" :key="option.value" class="mb-3">
          <v-radio class="d-inline-block" :value="option.value" />
          <stemble-latex :content="option.expression" />
        </div>
      </v-radio-group>

      <p class="mb-3">
        Which of the following is the proper form for the solubility product of
        <chemical-latex content="Mg3(PO4)2" />
        with respect to the molar solubility,
        <stemble-latex :content="'$S$'" />
        ?
      </p>

      <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
        <div v-for="option in options2" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value" />
          <stemble-latex :content="option.expression" />
        </div>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question122',
  components: {StembleLatex, ChemicalLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
      },
      options1: [
        {
          expression: '$K_{\\text{sp}} = \\frac{\\ce{[Mg^{2+}][PO4^{3-}]}}{\\ce{[Mg3(PO4)2]}}$',
          value: '1',
        },
        {
          expression: '$K_{\\text{sp}} = \\ce{[Mg^{2+}]}^3\\ce{[PO_4^{3-}]}^2$',
          value: '2',
        },
        {
          expression: '$K_{\\text{sp}} = \\frac{\\ce{[Mg^{2+}]^3[PO4^{3-}]^2}}{\\ce{[Mg3(PO4)2]}}$',
          value: '3',
        },
        {
          expression: '$K_{\\text{sp}} = \\ce{[Mg^{2+}]}\\ce{[PO_4^{3-}]}$',
          value: '4',
        },
      ],
      options2: [
        {expression: '$K_{\\text{sp}} = S^5$', value: '1'},
        {expression: '$K_{\\text{sp}} = S^2$', value: '2'},
        {expression: '$K_{\\text{sp}} = 108\\,S^5$', value: '3'},
        {expression: '$K_{\\text{sp}} = 6\\,S^2$', value: '4'},
      ],
    };
  },
};
</script>
